
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Monoton:wght@100;200;300;400;500;600;700;800&display=swap');

*{
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
}

.sidebar {
  color: #ffffff;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #ffffff;
  margin-top: 1rem;
}

.logo-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 1.5rem;
  border-radius: 50%;
  background-color: rgba(28,17,41,0.88);
}

.menu-bar {
  height: 90vh;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap:2;
  font-size: 1rem;
}
